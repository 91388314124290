import React, {useEffect, useState} from 'react';
import './home.scss';
import DashboardService from "../../api/service/DashboardService";
import {useAuth} from "../../contexts/auth";
import LoginPopUp from "../../components/login-form/login-pop-up/login-pop-up";
import {Link} from "react-router-dom";
import KiteLoginComponent from "../auto-trade/auth/KiteLoginComponent";
import KiteAuthService from "../../api/service/KiteAuthService";
import Notification from "../../utils/notification-toast";
import {
    ANGELONE_OAUTH_REDIRECT_URL,
    KITE_OAUTH_REDIRECT_URL,
    KITE_OAUTH_REDIRECT_URL_ERROR
} from "../../constants/UrlServiceConstants";
import AngelOneLoginComponent from "../auto-trade/auth/AngelOneLoginComponent";
import AngelOneAuthService from "../../api/service/AngelOneAuthService";

export default () => {
    const {user} = useAuth();
    const [loading, setLoading] = useState(false);

    const [loginPopupVisible, setLoginPopupVisible] = useState(false);
    const [isKiteApiKeysExists, setKiteApiKeysExists] = useState(false);
    const [isKiteLoggedIn, setIsKiteLogged] = useState(false);

    useEffect(() => {
        //loadDashboardInfo()
        if (user) {
            getTradeAuthInfo()
        }
    }, []);

    function getTradeAuthInfo() {
        setLoading(true)
        KiteAuthService.getTradeAuthInfo()
            .then(resp => {
                if (resp.status === 200) {
                    const respData = resp.data;
                    if (respData.kiteApiInfo?.hasApiInfo === true) {
                        setKiteApiKeysExists(true)
                    }
                    if (respData.authDetails.hasAccessToken === true && respData.kiteApiInfo.hasApiInfo === true) {
                        setIsKiteLogged(true)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                Notification.error("something went wrong!")
                console.log(err)
            })
    }

    function loginWithAngelOne() {
        setLoading(true);

        console.log('load angelone auth url');
        AngelOneAuthService.getAngelOneAuthUrl().then(resp => {
            setLoading(false)
            if (resp.status === 200) {
                openAngelOneLoginPopUp(resp.data)
            } else {
                Notification.warning("Please connect your AngelOne Account.")
            }
        }).catch(err => {
            console.log('Error occurred ' + err);
            setLoading(false)
            Notification.warning("Please connect your AngelOne Account.")
        })
    }

    function loginWithKite() {
        setLoading(true)
        KiteAuthService.getKiteAuthUrl().then(resp => {
            setLoading(false)
            if (resp.status === 200) {
                openKiteLoginPopUp(resp.data)
            } else {
                showKiteLoginMsg()
            }
        }).catch(err => {
            setLoading(false)
            showKiteLoginMsg()
        })
    }

    function showKiteLoginMsg() {
        Notification.warning("Please connect your Kite Account.")
    }


    function openAngelOneLoginPopUp(authUrl) {
        const angelOneAuthPopUpWindow = window.open(authUrl, "", "width=600,height=500, left=400, top= 50");
        console.log('opening angelone popup, url: ' + authUrl);

        let pollingTimer = window.setInterval(async () => {
            try {
                const popUpWinUrl = angelOneAuthPopUpWindow.document.URL;
                console.log("popUpWinUrl " + popUpWinUrl);

                if (popUpWinUrl.startsWith(ANGELONE_OAUTH_REDIRECT_URL)) {
                    window.clearInterval(pollingTimer);
                    const urlParams = new URL(popUpWinUrl);
                    const oauthAuthToken = urlParams.searchParams.get("auth_token");
                    const oauthRefreshToken = urlParams.searchParams.get("refresh_token");

                    if (oauthAuthToken !== "") {
                        await getAngelOneRequestToken(oauthAuthToken);
                        console.log('next step with auth-token ' + oauthAuthToken)
                        angelOneAuthPopUpWindow.close();
                        window.location.reload()
                    }
                } /*else {
                    console.log("There was a problem connecting to angelOne. URL is " + popUpWinUrl);
                    window.clearInterval(pollingTimer);
                    angelOneAuthPopUpWindow.close();
                    Notification.error("There was a problem connecting to AngelOne, try again later!")
                }*/
            } catch (e) {
                console.log(e);
            }
        }, 300);
    }

    function openKiteLoginPopUp(authUrl) {
        const kiteAuthPopUpWindow = window.open(authUrl, "", "width=500,height=500, left=500, top= 100");

        let pollingTimer = window.setInterval(async () => {
            try {
                const popUpWinUrl = kiteAuthPopUpWindow.document.URL;

                if (popUpWinUrl.startsWith(KITE_OAUTH_REDIRECT_URL)) {
                    window.clearInterval(pollingTimer);
                    const urlParams = new URL(popUpWinUrl);
                    const kiteAuthStatus = urlParams.searchParams.get("status");
                    const oauthReqToken = urlParams.searchParams.get("request_token");

                    if (kiteAuthStatus === "success" && oauthReqToken !== "") {
                        await getKiteRequestToken(oauthReqToken);
                        kiteAuthPopUpWindow.close();
                        window.location.reload()
                    }
                }
                if (popUpWinUrl.startsWith(`${KITE_OAUTH_REDIRECT_URL_ERROR}`)) {
                    window.clearInterval(pollingTimer);
                    kiteAuthPopUpWindow.close();
                    Notification.error("There was a problem connecting to Zerodha Kite, try again later!")
                }
            } catch (e) {
            }
        }, 100);
    }

    async function getKiteRequestToken(reqToken) {
        await KiteAuthService.createSession(reqToken).then(resp => {
            Notification.success("Login successful")
            window.location.reload();
        }).catch(err => {
            Notification.error(err)
        })
    }
    async function getAngelOneRequestToken(authToken) {
        await AngelOneAuthService.createSession(authToken).then(resp => {
            Notification.success("Login successful")
            window.location.reload();
        }).catch(err => {
            console.log("error: " + err)
            Notification.error(err)
        })
    }

    const closeLoginPopup = () => {
        setLoginPopupVisible(!loginPopupVisible);
    };

    return (
        <React.Fragment>
            {loading ? (
                <div>
                    <span>
                        <span>Loading <span className={'dot-loader'}></span></span>
                    </span>
                </div>
            ) : (
                <div>
                    <div className={'dashboard-title'}>
                        Welcome!
                        {user && (
                            <span>
                                {` ${user?.name}`}
                            </span>
                        )}
                        <br/><br/>
                        {!user && (
                            <span>
                            {` `} Please login with your Google Account to continue {' '}
                                <a className={"link-text"} onClick={() => setLoginPopupVisible(true)}>
                            Click here to Login
                                </a>
                            </span>
                        )}
                    </div>
                    <div className={'dashboard-body'}>
                        {user && (
                            <div>
                                {isKiteApiKeysExists && (
                                    <div>
                                        <div>You need to connect your trading account to Trade Strategies. <br/><br/>
                                            Please connect with via Zerodha or AngelOne by choosing button below <br/>
                                            <br/>
                                        </div>

                                        {!isKiteLoggedIn && (
                                            <div>
                                                <KiteLoginComponent login={loginWithKite}
                                                                    hasKiteApi={isKiteApiKeysExists}/>
                                            </div>
                                        )}

                                        {isKiteLoggedIn &&
                                            (
                                                <div>
                                                    You are all set, setup your strategies and start trading.
                                                    <br/><br/>
                                                    <Link to={"/auto-trade"}
                                                          className={"link-text "}>View Strategies</Link>
                                                </div>
                                            )}
                                    </div>
                                )}

                                {!isKiteApiKeysExists && (
                                    <div>
                                        Please configure your Zerodha Kite API details to continue <Link
                                        className={"link-text"}
                                        to={"/preferences"}>
                                        Add here</Link>
                                    </div>
                                )}

                                <br/>
                                <div><AngelOneLoginComponent login={loginWithAngelOne}/></div>
                            </div>

                        )}
                    </div>
                    <LoginPopUp loginPopupVisible={loginPopupVisible} closeLoginPopup={closeLoginPopup}/>
                </div>
            )}
        </React.Fragment>
    )
};
