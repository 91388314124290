import React from 'react'
import {Button} from "devextreme-react/button";
import {Link} from "react-router-dom";

const AngelOneLoginComponent = (props) => {
    return (
        <div className={"kit-login"}>
            <div>

                <Button
                    icon={'https://d2u8j8b25aupc8.cloudfront.net/assets/icons/logo.svg'}
                    text={'Connect AngelOne'}
                    width={'auto'}
                    onClick={props.login}
                    disabled={false}
                >
                </Button>
            </div>
        </div>
    )
}
export default AngelOneLoginComponent;