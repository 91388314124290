import React, {useEffect, useState} from 'react'
import './admin.scss'
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Button from 'devextreme-react/button';
import AdminService from "../../api/service/admin/AdminService";
import Notification from "../../utils/notification-toast"
import {Toaster} from "react-hot-toast";
import ValidationConfig from "./ValidationConfig";
import config from './config'
import HolidaysService from "../../api/service/admin/HolidaysService";
import DateHelper from "../../helper/DateHelper";
import {Popup} from "devextreme-react/popup";
import DateBox from "devextreme-react/date-box";
import TextBox from 'devextreme-react/text-box';
import {DATE_FORMAT} from "../../constants/DateConstants";
import OhlcTabComp from "./OhlcTabComp";

const Admin = () => {
    // status
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [isFileOK, setIsFileOK] = useState(false);
    const [newHolidayModalStatus, setNewHolidayModalStatus] = useState(false);
    const [removeHolidayModalStatus, setRemoveHolidayModalStatus] = useState(false);

    // info
    const currYear = new Date().getFullYear();
    const years = config.getYears(currYear, 10);
    const [tradingHolidaysInfo, setTradingHolidaysInfo] = useState([]);
    const [deleteHolidayInfo, setDeleteHolidayInfo] = useState({});

    // inputs
    const [file, setFile] = useState([]);
    const [tradingYear, setTradingYear] = useState(currYear);

    const [holidayDate, setHolidayDate] = useState(new Date());
    const [holidayDesc, setHolidayDesc] = useState('');

    useEffect(() => {
        setFile([]);
        loadTradingHolidays()
    }, [tradingYear]);

    const prepareDate = (year) => {
        return DateHelper.stringToDate(`${year}-01-01`);
    };

    const loadTradingHolidays = () => {
        setLoading(true);
        HolidaysService.loadTradingHolidays(prepareDate(tradingYear)).then(resp => {
            setLoading(false);
            if (resp.status === 200) {
                setTradingHolidaysInfo(resp.data?.tradingHolidaysInfo);
            } else {
                setTradingHolidaysInfo([])
            }
        }).catch(err => {
            setLoading(false);
            setTradingHolidaysInfo([])
        })
    };

    const closeHolidayModal = () => {
        setNewHolidayModalStatus(!newHolidayModalStatus)
    };

    const confirmDeleteHoliday = (info) => {
        setRemoveHolidayModalStatus(true)
        setDeleteHolidayInfo(info)
    };

    const deleteHoliday = (isDelete) => {
        setLoading(true);
        if (isDelete) {
            HolidaysService.deleteTradingHolidays(deleteHolidayInfo?.id)
                .then(resp => {
                    setLoading(false);
                    setRemoveHolidayModalStatus(false);
                    if (resp.status === 200) {
                        Notification.success("Deleted Successfully.");
                        loadTradingHolidays()
                    }
                })
                .catch(err => {
                    setLoading(false);
                    setRemoveHolidayModalStatus(false);
                    Notification.success("Unable Delete!")
                });
        } else {
            setDeleteHolidayInfo({})
            setRemoveHolidayModalStatus(false);
        }
    };

    const saveHoliday = () => {
        setLoading(true);
        HolidaysService.saveTradingHolidays({
            holidayDate: DateHelper.stringToDate(holidayDate),
            description: holidayDesc
        }).then(resp => {
            setLoading(false);
            closeHolidayModal();
            loadTradingHolidays()
        }).catch(err => {
            setLoading(false);
            Notification.error("Unable to create")
            closeHolidayModal()
        })
    };

    const fetchAndSyncKiteInstruments = () => {
        setLoading(true)
        AdminService.fetchAndSyncKiteInstruments()
            .then(resp => {
                if (resp.status === 200) {
                    Notification.success("Kite instruments instruments import successful")
                } else {
                    Notification.error("error in importing kite instruments")
                }
                setLoading(false)
            }).catch(err => {
            Notification.error("error in importing kite instruments")
            setLoading(false)
            console.log("err:", err)
        })
    }

    const upload = () => {
        if (file.length > 0) {
            if (isFileOK) {
                setLoading(true);
                AdminService.uploadBhavCopy(file[0]).then(resp => {
                    setLoading(false);
                    if (resp.status === 200) {
                        setFile([]);
                        setSuccessMsg(`Data timestamp: ${resp.data.dataTimestamp} imported records: ${resp.data.importedRecords}`)
                        Notification.success("Upload Successful.");
                        setTimeout(() => {
                            setSuccessMsg('')
                        }, 4000)
                    } else {
                        setLoading(false)
                        Notification.error("Unable to upload the file!");
                    }
                }).catch(err => {
                    setLoading(false)
                    Notification.error("Unable to upload the file!");
                })
            } else {
                Notification.error("Unable to upload the file!")
            }
        } else {
            Notification.error("Please select the file to upload.")
        }
    };

    function setBhavCopyFile(file) {
        const isOK = ValidationConfig.validateFile(file);
        if (isOK) {
            setIsFileOK(true);
            setFile(file)
        } else {
            setIsFileOK(false);
            setErrMsg('Not able to upload!')
        }
    }

    return (
        <div className={"admin-page"}>
            <Tabs>
                <TabList>
                    <Tab><span><i className="dx-icon-user"></i> OHLC</span></Tab>
                    <Tab><span><i className="dx-icon-upload"></i> Instruments </span></Tab>
                    <Tab><span><i className="dx-icon-user"></i> Manage Users</span></Tab>
                    <Tab><span><i className="dx-icon-event"></i> Manage Holidays</span></Tab>
                </TabList>

                <TabPanel>
                    <OhlcTabComp/>
                </TabPanel>

                <TabPanel>
                    <div className={"upload"}>
                        <form id="form">
                            {successMsg &&
                            <h6 style={{
                                backgroundColor: '#ebfaeb',
                                margin: 0,
                                borderLeft: '4px solid',
                                borderRadius: '2px',
                                paddingLeft: '4px'
                            }}>
                                {successMsg}
                            </h6>}

                            <div style={{padding: '15px'}}>
                                {loading && (
                                    <div>
                                        <span style={{color: '#9e9e9e'}}>Loading Instruments data... <span
                                            className={'dot-loader'}></span></span>
                                    </div>
                                )}
                                <Button className="button"
                                        disabled={loading}
                                        text={`Load Kite Instruments`}
                                        onClick={fetchAndSyncKiteInstruments}
                                        type="success"/>

                                <br/><br/><hr/><br/>
                                <Button className="button"
                                        disabled={loading}
                                        text={`Load AngelOne Instruments`}
                                        onClick={fetchAndSyncKiteInstruments}
                                        type="success"/>
                            </div>
                        </form>
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className={"tab-content"}>
                        <div>User Management</div>
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className={"holiday-container"}>
                        <div className={"holiday-body"}>
                            <table className={"holidays-table"}>
                                <thead>
                                <tr>
                                    <th colSpan={5}>
                                        <span style={{float: 'left'}}>
                                            <span style={{
                                                fontSize: '15px',
                                                color: 'grey',
                                                fontWeight: 'normal'
                                            }}>
                                            Choose the Year {''}
                                        </span>
                                        <select title={"Select the trading year"}
                                                style={{fontSize: '15px'}}
                                                defaultValue={currYear}
                                                onChange={(e) => {
                                                    setTradingYear(e.target.value)
                                                }}>
                                            {years.map((item, index) =>
                                                (
                                                    <option key={index}>
                                                        {item}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                            {
                                                loading && (
                                                    <span style={{color: '#9e9e9e'}}>
                                                        {" "}Loading <span className={'dot-loader'}/></span>
                                                )
                                            }
                                        </span>
                                        <span style={{float: 'right'}}>
                                        <button onClick={() => setNewHolidayModalStatus(!newHolidayModalStatus)}>
                                            <strong>
                                                <i className="dx-icon-add"></i>
                                                New Holiday</strong>
                                        </button>
                                            </span>
                                    </th>
                                </tr>
                                <tr>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Day</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tradingHolidaysInfo.length !== 0 ?
                                    tradingHolidaysInfo?.map((item, index) =>
                                        (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.holidayDate}</td>
                                                <td>{item.day}</td>
                                                <td>{item.description}</td>
                                                <td>
                                                    <button
                                                        className={"delete-holiday-btn"}
                                                        title={"Delete Holiday"}
                                                        onClick={() => confirmDeleteHoliday(item)}>
                                                        <i className="dx-icon-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    )
                                    :
                                    <tr>
                                        <td colSpan={4}> No Data found</td>
                                    </tr>
                                }
                                </tbody>
                            </table>

                            {/*Add Holiday Modal*/}
                            <Popup
                                width={300}
                                height={300}
                                visible={newHolidayModalStatus}
                                title={"New Holiday"}
                                showCloseButton={true}
                                onHiding={closeHolidayModal}
                            >
                                <div>
                                    <label style={{color: 'grey'}}>Holiday Date</label>
                                    <DateBox
                                        defaultValue={new Date()}
                                        displayFormat={DATE_FORMAT}
                                        width={'auto'}
                                        value={holidayDate}
                                        type="date"
                                        onValueChanged={(e) => setHolidayDate(e.value)}
                                    />
                                    <br/>
                                    <label style={{color: 'grey'}}>Description</label>
                                    <TextBox defaultValue=""
                                             value={holidayDesc}
                                             showClearButton={true}
                                             onValueChange={(e) => setHolidayDesc(e)}/>
                                    <br/>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        <Button className="button"
                                                text={`Save`}
                                                onClick={saveHoliday}
                                                type="default"/>
                                    </div>
                                </div>
                            </Popup>
                        </div>

                        {/*Delete Holiday Modal*/}
                        <div>
                            <Popup
                                width={300}
                                height={200}
                                visible={removeHolidayModalStatus}
                                title={"Delete Holiday"}
                                showCloseButton={true}
                                onHiding={() => {
                                    setDeleteHolidayInfo({})
                                    setRemoveHolidayModalStatus(!removeHolidayModalStatus)
                                }}
                            >
                                <div>
                                    <div>
                                        <span
                                            style={{color: 'grey'}}>Date: </span><span><strong>{deleteHolidayInfo?.holidayDate}</strong></span><br/>
                                        <span
                                            style={{color: 'grey'}}>Description: </span><span><strong>{deleteHolidayInfo?.description}</strong></span>
                                    </div>
                                    <br/>
                                    <div>
                                        <Button className="button"
                                                text={`Yes`}
                                                onClick={() => deleteHoliday(true)}
                                                type="default"/>
                                        <span>{' '}</span>
                                        <Button className="button"
                                                text={`No`}
                                                onClick={() => deleteHoliday(false)}
                                                type="normal"/>
                                    </div>
                                </div>
                            </Popup>
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
            <Toaster/>
        </div>
    )
};

export default Admin;
